import { useLayout } from '@react-native-community/hooks';
import { debounce } from 'lodash';
import { Platform } from 'react-native';

export function useChartLayout() {
    const { onLayout, width } = useLayout();

    return {
        width,
        onLayout: Platform.OS === 'web' ? debounce(onLayout, 100) : onLayout,
    };
}
