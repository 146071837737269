import { Icon, IconNames, Stack, Text } from '@alltis/ui';
import React, { PropsWithChildren } from 'react';

type Props = {
    icon: IconNames;
    title: string;
};

const ReportPopoverOption = (props: PropsWithChildren<Props>) => {
    return (
        <Stack py={12} px={16} alignItems='flex-start' flexDirection='row'>
            <Stack flex={1} flexDirection='row' alignItems='center' pt={8}>
                <Icon name={props.icon} color='#588290' size={24} />

                <Text ml={16} color='#8CA9B3' fontWeight={400} fontSize={13}>
                    {props.title}
                </Text>
            </Stack>

            <Stack w={192}>{props.children}</Stack>
        </Stack>
    );
};

export default ReportPopoverOption;
