import SenseCard from '@/sense/components/SenseCard';
import { useSense } from '@/sense/hooks/fetch/useSense';
import { useMainStatusBar } from '@/shared/hooks/useMainStatusBar';
import { PageList } from '@alltis/ui';
import React from 'react';
import { Stack } from 'tamagui';
import AppBar from '../../../../navigation/stacks/User/Main/components/MainHeader/AppBar';
import { useNavigation } from '@react-navigation/native';

const RationConsumption = () => {
    useMainStatusBar();

    const navigation = useNavigation();

    const { data, isError, isLoading, refetch, dataUpdatedAt } = useSense();

    return (
        <Stack flex={1}>
            <AppBar onReportPress={() => navigation.navigate('SenseReport', { senseId: '' })} />

            <PageList
                data={data}
                isError={isError}
                isLoading={isLoading}
                dataUpdatedAt={dataUpdatedAt}
                renderItem={({ item }) => <SenseCard sense={item.sense} />}
                onRefresh={refetch}
            />
        </Stack>
    );
};

export default RationConsumption;
