import { useQuery } from '@tanstack/react-query';
import { alltisApiV2 } from '../../../../config/api/alltisApiV2';
import { ChickenPostureResponse } from '@/chicken-house/api/types/ChickenPostureResponse';

export function useChickenPoture(eggVisionId: string) {
    return useQuery({
        queryKey: ['chicken-posture', eggVisionId],
        queryFn: async () => (await alltisApiV2.get<ChickenPostureResponse>(`/exp/chickens/posture/${eggVisionId}`)).data,
    });
}
