import { Icon, useSheetCheck } from '@/alltis-ui-v2/components';
import React from 'react';
import { Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Separator, Stack, Text, useMedia } from 'tamagui';
import { HeaderProps } from './HeaderProps';

const Header = (props: HeaderProps) => {
    const media = useMedia();
    const safeArea = useSafeAreaInsets();

    const isSheet = useSheetCheck();

    const height = props.size === 'sm' ? 48 : 64;

    return (
        <Stack
            pt={isSheet ? 0 : Platform.OS === 'android' ? safeArea.top : 0}
            backgroundColor={props.bg || '#F5F5F5'}
            borderTopLeftRadius={8}
            borderTopRightRadius={8}
        >
            <Stack h={height} p={8} zIndex={999} flexDirection='row' jc='center' ai='center'>
                <Stack
                    flex={1}
                    position='absolute'
                    left={media.md ? 0 : undefined}
                    right={media.md ? undefined : 0}
                    p={8}
                    cursor='pointer'
                >
                    {props.onClose && (
                        <Stack onPress={props.onClose}>
                            <Icon name='close' size={34} color='#376A7B' />
                        </Stack>
                    )}
                </Stack>

                <Text fontSize={18} color='#588290' fontWeight={500}>
                    {props.title}
                </Text>

                {media.md && props.renderActions && (
                    <Stack flex={1} position='absolute' right={0} p={8}>
                        {props.renderActions?.()}
                    </Stack>
                )}
            </Stack>

            {!media.md && props.renderActions && (
                <Stack bg='#ffffff' flexDirection='column'>
                    <Stack my={16} mx={20}>
                        {props.renderActions?.()}
                    </Stack>
                </Stack>
            )}

            {props.divider !== false && <Separator borderBottomColor='#EBEBEA' />}
        </Stack>
    );
};

export default Header;
