import { Icon, IconNames, Stack } from '@alltis/ui';
import React from 'react';

type Props = {
    icon: IconNames;
    isDanger?: boolean;
    color?: string;
};

const CardIcon = (props: Props) => {
    if (props.isDanger) {
        return (
            <Stack w={36} h={36} bg='#8C1913' br={8} jc='center' ai='center'>
                <Icon name='alert-triangle' size={24} color='#ffffff' />
            </Stack>
        );
    }

    return <Icon name={props.icon} size={24} color={props.color} />;
};

export default CardIcon;
