import { PageList } from '@/alltis-ui-v2';
import AquaAppBar from '@/aqua/components/AquaAppBar';
import AquaCard from '@/aqua/components/AquaCard';
import { useAquaList } from '@/aqua/hooks/useAquaList';
import { useMainStatusBar } from '@/shared/hooks/useMainStatusBar';
import React from 'react';
import { Stack } from 'tamagui';

const Aqua = () => {
    useMainStatusBar();

    const { data, isError, isLoading, refetch, dataUpdatedAt } = useAquaList();

    return (
        <Stack flex={1}>
            <AquaAppBar />

            <PageList
                data={data}
                isError={isError}
                isLoading={isLoading}
                dataUpdatedAt={dataUpdatedAt}
                renderItem={({ item }) => <AquaCard aqua={item.aqua} site={item.site} />}
                onRefresh={refetch}
            />
        </Stack>
    );
};

export default Aqua;
