import React, { useMemo } from 'react';
import { iconSet } from './iconSet';
import { IconProps } from './types';
import Feather from '@expo/vector-icons/Feather';

const Icon = (props: IconProps) => {
    const Icon = useMemo(() => {
        return iconSet.find((icon) => icon.name === props.name)?.icon;
    }, [props.name]);

    if (!Icon) return <Feather {...props} name={props.name as any} />;

    return <Icon {...props} />;
};

export default Icon;
