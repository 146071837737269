import { createContext, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { SelectItem, SelectProps, SelectValue } from '../SelectProps';
import { HandleOnChangeOptions, useOnChange } from '../hooks/useOnChange';

type SelectContextType = {
    onToggleItem: (item: SelectItem) => void;
    isItemSelected: (item: SelectItem) => boolean;
    onSelectAll: () => void;
    activeItems: SelectValue[];
    isAllSelected: boolean;
    handleOnChange: (items: SelectValue[], options?: HandleOnChangeOptions) => void;
};

export const SelectContext = createContext<SelectContextType>({} as SelectContextType);

export const SelectProvider = (props: PropsWithChildren<SelectProps>) => {
    const [activeItems, setActiveItems] = useState<SelectValue[]>(() => {
        if (props.multiple) {
            if (!props.value?.length && props.selectAllWhenEmpty) {
                return props.items?.map((item) => item.value) ?? [];
            }

            return props.value;
        }

        return [props.value];
    });

    const { handleOnChange } = useOnChange(props);

    const onToggleItem = useCallback(
        (item: SelectItem) => {
            let nextItems: SelectValue[] = [];

            setActiveItems((prevItems) => {
                if (prevItems.some((activeItem) => activeItem === item.value)) {
                    nextItems = prevItems.filter((activeItem) => activeItem !== item.value);
                } else {
                    nextItems = [...prevItems, item.value];
                }

                return nextItems;
            });

            handleOnChange(nextItems);
        },
        [handleOnChange]
    );

    const isItemSelected = useCallback(
        (item: SelectItem) => {
            return activeItems.some((activeItem) => activeItem === item.value);
        },
        [activeItems]
    );

    const isAllSelected = useMemo(() => {
        const totalItems = props.items?.length ?? 0;
        return totalItems > 0 && activeItems.length === totalItems;
    }, [props.items, activeItems, props.selectAllWhenEmpty]);

    const onSelectAll = useCallback(() => {
        setActiveItems((prevItems) => {
            if (!props.items) return prevItems;
            const nextItems = isAllSelected ? [] : props.items.map((item) => item.value);
            handleOnChange(nextItems);
            return nextItems;
        });
    }, [props.items, isAllSelected, handleOnChange]);

    return (
        <SelectContext.Provider
            value={{
                activeItems,
                isItemSelected,
                onToggleItem,
                isAllSelected,
                onSelectAll,
                handleOnChange,
            }}
        >
            {props.children}
        </SelectContext.Provider>
    );
};
