import { PortalHost } from '@gorhom/portal';
import React, { ReactNode } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Stack } from 'tamagui';
import { useLargeLayout } from '../../../../../../../hooks/useLargeLayout';

type Props = {
    middle?: ReactNode;
    end?: ReactNode;
    start?: ReactNode;
};

const HeaderLayout = ({ start, end, middle }: Props) => {
    const isLarge = useLargeLayout();
    const { top } = useSafeAreaInsets();

    const headerSize = isLarge ? 77 : 56;

    return (
        <>
            <Stack
                h={top + headerSize}
                width='100%'
                backgroundColor={isLarge ? '#ffffff' : '#05455A'}
                elevationAndroid={5}
                shadowColor='#000000'
                shadowOpacity={0.1}
                shadowOffset={{ width: 0, height: 4 }}
                shadowRadius={16}
                pt={top}
                px={14}
                pr={18}
            >
                <Stack flex={1} jc='center' ai='center' flexDirection='row'>
                    <Stack mr={12} height='100%' jc='center' ai='center'>
                        {start}
                    </Stack>

                    <PortalHost name='app_bar_left' />

                    {isLarge ? <Stack flex={1} /> : middle}

                    <PortalHost name='app_bar_right' />

                    <Stack ml={12}>{end}</Stack>
                </Stack>
            </Stack>
        </>
    );
};

export default HeaderLayout;
