import { Icon } from '@/alltis-ui-v2/components/Icon';
import React from 'react';
import SelectListItem from '../SelectListItem';
import { useSelect } from '../../hooks/useSelect';
import { SelectItem } from '../../SelectProps';

const SelectAllButton = () => {
    const { isAllSelected, onSelectAll } = useSelect();

    const selectAll: SelectItem = {
        label: 'Todos',
        value: 'select-all',
        caption: 'Selecionar todos os itens',
        icon: <Icon name='list' />,
    };

    return <SelectListItem item={selectAll} isSelected={isAllSelected} onSelect={onSelectAll} />;
};

export default SelectAllButton;
