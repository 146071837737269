import { Popover, Stack, useMedia } from '@alltis/ui';
import { useLayout } from '@react-native-community/hooks';
import React from 'react';
import { SelectInput } from './components/SelectInput';
import SelectList from './components/SelectList';
import { SelectProvider } from './context/SelectContext';
import { SelectProps } from './SelectProps';

const Select = <Multiple,>(props: SelectProps<Multiple>) => {
    const { onLayout, width } = useLayout();
    const media = useMedia();

    return (
        <Stack w='100%'>
            <Popover>
                <Popover.Trigger cursor='default'>
                    <Stack onLayout={onLayout}>
                        <SelectInput {...(props as SelectProps)} />
                    </Stack>
                </Popover.Trigger>

                <Popover.Content
                    height={400}
                    width={media.sm ? Math.max(width, 300) : '100%'}
                    disableArrow
                    disableTransition
                    elevation={3}
                >
                    <SelectProvider {...(props as SelectProps)}>
                        <SelectList {...(props as SelectProps)} />
                    </SelectProvider>
                </Popover.Content>
            </Popover>
        </Stack>
    );
};

export default Select;
