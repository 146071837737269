import { Box, Fade, Popover as MuiPopover } from '@mui/material';
import React, { useContext } from 'react';
import { Stack } from 'tamagui';
import { PopoverContentProps } from '../../PopoverProps';
import { PopoverContext } from '../../context/PopoverContext';

const PopoverContent = (props: PopoverContentProps) => {
    const popoverContext = useContext(PopoverContext);

    const open = Boolean(popoverContext.anchor);

    const handleClose = () => {
        popoverContext.setAnchor(null);
    };

    return (
        <MuiPopover
            anchorEl={popoverContext.anchor}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            keepMounted={popoverContext.props.keepMounted}
            {...popoverContext.props}
            slotProps={{
                paper: {
                    elevation: props?.elevation || undefined,
                    sx: { overflow: 'visible' },
                },
            }}
            sx={{ marginTop: props.disableArrow ? '6px' : '19px', zIndex: props.allowClickOutside ? 'unset' : undefined }}
            TransitionComponent={props.disableTransition ? Fade : undefined}
            TransitionProps={props.disableTransition ? { timeout: 0 } : undefined}
            disablePortal={props.allowClickOutside}
        >
            {!props.disableArrow && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '-9px',
                        right: 10,
                        marginLeft: '-5px',
                    }}
                >
                    <Box
                        sx={{
                            width: '20px',
                            height: '20px',
                            bgcolor: 'background.paper',
                            transform: 'rotate(45deg)',
                            boxShadow: '0px -0px 5px rgba(0, 0, 0, 0.1)',
                            borderTopLeftRadius: 5,
                        }}
                    />

                    <Box
                        width={40}
                        height={30}
                        position='absolute'
                        sx={{ background: 'white', right: 0, marginRight: '-8px', marginTop: '-10px', borderRadius: '8px' }}
                    />
                </Box>
            )}

            <Stack w={props.width ?? 500} h={props.height}>
                {props.children}
            </Stack>
        </MuiPopover>
    );
};

export default PopoverContent;
