import { PageList } from '@/alltis-ui-v2';
import { RANGE_OPTIONS } from '@/sense/constants/rangeOptions';
import { Sense } from '@/sense/entities/Sense';
import { useSensePanel } from '@/sense/store /useSensePanel';
import { usePanel } from '@/shared/components/ui/Panel/hooks/usePanel';
import FontAwesome6 from '@expo/vector-icons/FontAwesome6';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Stack } from 'tamagui';
import VerticalBarIndicator from '../VerticalBarIndicator';

type Props = {
    sense: Sense;
};

const SenseCard = ({ sense }: Props) => {
    const navigation = useNavigation();

    const panel = usePanel();
    const sensePanel = useSensePanel();

    function onPress() {
        if (panel.isPanel) {
            return sensePanel.onSelectSense(sense.id);
        }

        navigation.navigate('SenseReport', { senseId: sense.id });
    }

    return (
        <PageList.Card onPress={onPress}>
            <PageList.CardHeader title={sense.name} icon='ambience' isDanger={sense.alert} />

            <PageList.CardContent pt={0}>
                <Stack flexDirection='row'>
                    <Stack flex={0.5} maxWidth='35%'>
                        <VerticalBarIndicator
                            icon={<FontAwesome6 name='temperature-half' />}
                            title='Temperatura'
                            unit='ºC'
                            value={sense.metrics.temperature}
                            range={RANGE_OPTIONS.temperature.range}
                            expectedRange={RANGE_OPTIONS.temperature.expected}
                            decimals={0}
                        />
                    </Stack>

                    <Stack flex={0.5} maxWidth='35%'>
                        <VerticalBarIndicator
                            icon={<FontAwesome6 name='droplet' />}
                            title='Umidade'
                            unit='%'
                            value={sense.metrics.relative_humidity * 100}
                            range={RANGE_OPTIONS.humidity.range}
                            expectedRange={RANGE_OPTIONS.humidity.expected}
                            decimals={0}
                        />
                    </Stack>

                    <Stack flex={0.3}>
                        <VerticalBarIndicator
                            icon={<FontAwesome6 name='cloud-arrow-down' />}
                            title='Nível CO²'
                            unit='ppm'
                            value={sense.metrics.co2}
                            range={RANGE_OPTIONS.co2.range}
                            expectedRange={RANGE_OPTIONS.co2.expected}
                        />
                    </Stack>
                </Stack>
            </PageList.CardContent>
        </PageList.Card>
    );
};

export default SenseCard;
