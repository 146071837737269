import { ChickenHouseInfo } from '@/chicken-house/entities/ChickenHouseInfo';
import { Header, Sheet, Stack, useMedia } from '@alltis/ui';
import { Portal } from '@gorhom/portal';
import React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Platform } from 'react-native';
import * as Device from 'expo-device';
import { useChickenPoture } from '@/chicken-house/hooks/chicken-posture/useChickenPosture';
import { PostureLineChart } from './PostureLineChart';
import PageListCardInfo from '@/alltis-ui-v2/components/PageList/components/PageListCardInfo';
import PostureCardInfo from './PostureCardInfo';
import { formatBigNumber } from '@/shared/utils/number';

type Props = {
    onClose: () => void;
    device: ChickenHouseInfo;
};

const ChickenPosture = (props: Props) => {
    const { bottom } = useSafeAreaInsets();
    const { data } = useChickenPoture(props.device.id);

    const media = useMedia();

    const isTablet = Device.deviceType === Device.DeviceType.TABLET && media.md;

    return (
        <Portal>
            <Sheet
                animateOnMount
                snapPoints={[bottom + 430]}
                keyboardBlurBehavior='restore'
                handleIndicatorStyle={{
                    backgroundColor: '#282828',
                }}
                keyboardBehavior='extend'
                onClose={props.onClose}
            >
                {(Platform.OS === 'web' || isTablet) && (
                    <Stack>
                        <Header title={props.device.name} onClose={props.onClose} />
                    </Stack>
                )}

                <Stack fd='row' jc='space-around' flexWrap={'wrap'} px={18} pt={24} $lg={{ px: 24 }} mt={20} maxWidth={'100%'}>
                    <Stack w={'100%'} fd={'row'} jc='space-around' mb={20}>
                        <PostureCardInfo title={'Lote'} value={data?.batch} />

                        <PostureCardInfo title={'Genética'} value={data?.genetic} />
                    </Stack>

                    <Stack w={'100%'} fd={'row'} jc='space-around' mb={20}>
                        <PostureCardInfo title={'Número de Aves'} value={formatBigNumber(data?.chicken_count || 0)} />

                        <PostureCardInfo title={'Postura da Última Semana'} value={`${(data?.last_week_prod || 0) * 100}%`} />
                    </Stack>
                </Stack>

                <Stack height={Platform.OS === 'web' ? 600 : 230} p={12} flex={isTablet ? 1 : undefined}>
                    <PostureLineChart
                        data={{
                            historic: data?.collected?.map((d) => ({
                                timestamp: d.week.toString(),
                                value: d.value * 100,
                            })),
                            expected: data?.expected.map((d) => ({
                                timestamp: d.week.toString(),
                                value: d.value * 100,
                            })),
                        }}
                        isDanger={props.device.eggCount.alert}
                    />
                </Stack>
            </Sheet>
        </Portal>
    );
};

export default ChickenPosture;
