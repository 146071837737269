import { useChickenHouses } from '@/chicken-house/hooks/places/useChickenHouses';
import { PageList, Stack } from '@alltis/ui';
import React from 'react';
import { FlatListProps } from 'react-native';
import { DEFAULT_FETCH_INTERVAL } from '../../../../config/constants/fetchConfig';
import { useLargeLayout } from '../../../../hooks/useLargeLayout';
import EggProductionTotalSummary from '../EggProduction/EggProductionTotalSummary';
import EggProductionCard from '../EggProductionCard';

type Props = Pick<FlatListProps<unknown>, 'ListEmptyComponent'>;

const PlacesList = (props: Props) => {
    const isLarge = useLargeLayout();

    const chickenHouses = useChickenHouses({ refreshInterval: DEFAULT_FETCH_INTERVAL });

    return (
        <PageList
            onRefresh={chickenHouses.refetch}
            data={chickenHouses.data || []}
            ListHeaderComponent={
                <Stack mb={24} $md={{ mr: 24 }}>
                    <EggProductionTotalSummary />
                </Stack>
            }
            renderItem={({ item }) => (
                <Stack pb={isLarge ? 12 : 0} flex={1}>
                    <EggProductionCard data={item} />
                </Stack>
            )}
            ListEmptyComponent={props.ListEmptyComponent}
            isLoading={chickenHouses.isLoading}
            isError={chickenHouses.isError}
            dataUpdatedAt={chickenHouses.dataUpdatedAt}
        />
    );
};

export default PlacesList;
