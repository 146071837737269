import { useWindowDimensions } from 'tamagui';
import { useMedia } from 'tamagui';

export function useResponsiveChart(width?: number) {
    const media = useMedia();
    const dimensions = useWindowDimensions();

    if (width) return width;

    if (media.xl) return 800;

    if (media.lg) return dimensions.width / 3;

    if (media.md) return dimensions.width / 2.5;

    if (media.sm) return 580;

    return 400;
}
