import React, { useState } from 'react';
import { RefreshControl as RNRefreshControl } from 'react-native';
import { RefreshControlProps } from './RefreshControlProps';

export const RefreshControl = ({ onRefresh, ...props }: RefreshControlProps) => {
    const [isRefreshing, setIsRefreshing] = useState(false);

    const handleRefresh = async () => {
        setIsRefreshing(true);

        props.onStart?.();

        try {
            await onRefresh();
        } finally {
            setIsRefreshing(false);
            props.onFinish?.();
        }
    };

    return (
        <RNRefreshControl
            {...props}
            tintColor='#8CA9B3'
            colors={['#8CA9B3']}
            refreshing={isRefreshing}
            onRefresh={handleRefresh}
        />
    );
};
