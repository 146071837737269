import { alltisApiV2 } from '../../../config/api/alltisApiV2';
import { ChickenHouseInfo } from '../entities/ChickenHouseInfo';
import { getIpv4FromString } from '../utils/network';
import { cameraScanApi } from './config/cameraScanApi';
import { ChickenHouseStreamResponse } from './types/ChickenHouseResponse';
import { EggVisionRemoteResponse } from './types/EggVisionResponse';

export async function getStreamChickenHouses(): Promise<ChickenHouseInfo[]> {
    const savedStreams = await cameraScanApi.get<ChickenHouseStreamResponse>('/stream');

    return savedStreams.data.map((response) => ({
        id: '',
        site: { name: response.nucleo },
        name: response.stream_name,
        cameraSource: {
            ip: getIpv4FromString(response.stream_url),
            streamMAC: getIpv4FromString(response.stream_mac),
            url: response.stream_url,
        },
        eggCount: {
            today: response.count_today ? Number(response.count_today) : 0,
            yesterday: response.count_yesterday ? Number(response.count_yesterday) : 0,
        },
    }));
}

export async function getRemoteServerChickenHouses(): Promise<Promise<ChickenHouseInfo[]>> {
    const response = await alltisApiV2.get<EggVisionRemoteResponse>('/egg-vision/list');

    return response.data.devices.map((c) => ({
        id: c.egg_vision.id,
        name: c.egg_vision.name,
        eggCount: {
            today: c.egg_vision.production.today,
            yesterday: c.egg_vision.production.yesterday,
            boxesToday: c.egg_vision.production.today_boxes,
            periodAvg: c.egg_vision.production.period_avg,
            periodSum: c.egg_vision.production.period_sum,
            periodSumBoxes: c.egg_vision.production.period_sum_boxes,
            periodAvgBoxes: c.egg_vision.production.period_avg_boxes,
            thresholdMin: c.egg_vision.production.threshold_min,
            thresholdMax: c.egg_vision.production.threshold_max,
            historic: c.egg_vision.production.historic?.map((h) => ({
                timestamp: new Date(h.timestamp),
                count: h.count,
                countBoxes: h.count_boxes,
                isMaximum: h.is_maximum,
                isMinimum: h.is_minimum,
            })),
            alert: c.egg_vision.production.alert,
            livePreview: c.egg_vision.live_preview,
            chickenPosture: c.egg_vision.chicken_posture,
        },
        location: c.location,
        site: c.site,
    }));
}
