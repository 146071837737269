import React, { forwardRef } from 'react';
import { TextInput } from 'react-native';
import { Stack } from 'tamagui';
import { Icon } from '../Icon';
import { fontSize } from '../Input/TextField/TextField';
import { SearchFieldProps } from './SearchFieldProps';

const SearchField = forwardRef<TextInput, SearchFieldProps>(({ containerProps, ...props }, ref) => {
    return (
        <Stack
            bg='#F5F5F5'
            borderRadius={4}
            jc='center'
            ai='center'
            fd='row'
            height={56}
            px={16}
            $md={{ px: 12 }}
            {...containerProps}
        >
            <Stack flex={1}>
                <TextInput
                    {...props}
                    style={[props.style, { flex: 1, fontSize: 14 }]}
                    ref={ref}
                    returnKeyType='search'
                    placeholderTextColor='#C0C0BD'
                    placeholder={props.placeholder || 'Pesquisar'}
                />
            </Stack>

            {!!props.value?.length && (
                <Stack
                    hitSlop={{
                        bottom: 20,
                        left: 20,
                        right: 20,
                        top: 20,
                    }}
                    onPress={() => props.onChangeText?.('')}
                    pressStyle={{ opacity: 0.7 }}
                >
                    <Icon name='close' color='#C0C0BD' size={16} />
                </Stack>
            )}

            <Stack>
                <Icon name='search' color='#C0C0BD' size={18} />
            </Stack>
        </Stack>
    );
});

SearchField.displayName = 'SearchField';

export default SearchField;
