import { IconNames, Stack, Text } from '@alltis/ui';
import React, { ReactNode } from 'react';
import CardIcon from '../PageListCard/CardIcon';
import PageListCardInfo, { PageListCardInfoProps } from '../PageListCardInfo';

type Props = {
    title: string;
    isDanger?: boolean;
    startContent?: PageListCardInfoProps;
    endContent?: PageListCardInfoProps;
    icon?: IconNames;
    backgroundColor?: string;
    foregroundColor?: string;
    headerActions?: ReactNode;
};

const PageListCardHeader = ({
    title,
    endContent,
    icon,
    startContent,
    isDanger,
    backgroundColor = '#F5F5F5',
    foregroundColor = '#588290',
    headerActions,
}: Props) => {
    return (
        <Stack>
            <Stack
                height={50}
                bg={backgroundColor}
                padding={-10}
                px={18}
                $lg={{ px: 24 }}
                ai='center'
                jc='space-between'
                flexDirection='row'
            >
                <Text
                    color={foregroundColor}
                    fontSize={16}
                    fontWeight={600}
                    numberOfLines={1}
                    textOverflow='ellipsis'
                    style={{ maxWidth: '90%' }}
                    flex={1}
                >
                    {title}
                </Text>

                <Stack flexDirection='row' jc='center' ai='center' gap={12}>
                    {headerActions}

                    <Stack>{!!icon && <CardIcon icon={icon} isDanger={isDanger} color={foregroundColor} />}</Stack>
                </Stack>
            </Stack>

            <Stack fd='row' jc='space-between' px={18} pt={24} $lg={{ px: 24 }}>
                {!!startContent && (
                    <PageListCardInfo
                        title={startContent?.title}
                        quantity={startContent.quantity}
                        caption={startContent.caption}
                        color={isDanger ? '#8C1913' : '#8DC044'}
                    />
                )}

                {!!endContent && (
                    <PageListCardInfo
                        title={endContent?.title}
                        quantity={endContent.quantity}
                        caption={endContent.caption}
                        color='#376A7B'
                    />
                )}
            </Stack>
        </Stack>
    );
};

export default PageListCardHeader;
