import React, { useContext } from 'react';
import { PopoverContentProps } from 'tamagui';
import { PopoverContext } from '../../context/PopoverContext';

const PopoverTrigger = (props: PopoverContentProps) => {
    const popoverContext = useContext(PopoverContext);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        return popoverContext.setAnchor(event.currentTarget);
    };

    return (
        <div style={{ cursor: props.cursor || 'pointer' }} onClick={handleClick}>
            {props.children}
        </div>
    );
};

export default PopoverTrigger;
