import { minBuildVersion } from '@/shared/utils/version';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import RNCheckbox from '@react-native-community/checkbox';
import React from 'react';
import { Platform } from 'react-native';
import { Checkbox as TCheckbox, useTheme } from 'tamagui';
import { CheckboxProps } from './CheckboxProps';

const Checkbox = (props: CheckboxProps) => {
    const { primary500 } = useTheme();

    if (!minBuildVersion('1.21.0') || Platform.OS === 'ios' || Platform.OS === 'web') {
        return (
            <TCheckbox
                checked={props.value}
                onCheckedChange={props.onValueChange}
                width={20}
                height={20}
                br={4}
                $md={{
                    width: 16,
                    height: 16,
                    br: 2,
                }}
                p={0}
                borderColor={props.value ? '#376A7B' : undefined}
                hoverStyle={{
                    borderColor: props.value ? '#376A7B' : undefined,
                }}
                focusStyle={{
                    borderColor: props.value ? '#376A7B' : undefined,
                }}
                overflow='hidden'
            >
                <TCheckbox.Indicator bg='#376A7B' width='100%' height='100%' jc='center' ai='center'>
                    <FontAwesome5 name='check' color='white' size={10} />
                </TCheckbox.Indicator>
            </TCheckbox>
        );
    }

    return (
        <RNCheckbox
            {...props}
            tintColors={{
                true: primary500.get() as string,
            }}
        />
    );
};

export default Checkbox;
