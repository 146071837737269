import { SelectValue } from '@/shared/components/ui/MultiSelect/MultiSelectProps';
import React from 'react';
import { Platform } from 'react-native';
import { Stack, Text, XStack } from 'tamagui';
import { Icon } from '../../../Icon';
import { FieldBase } from '../../../Input/FieldBase';
import { SelectProps } from '../../SelectProps';

type Props = SelectProps;

export const SelectInput = (props: Props) => {
    const getItemsByValues = (values: SelectValue[]) => {
        return props.items?.filter((item) => values?.includes(item.value));
    };

    const selectedItems = getItemsByValues(props.multiple ? props.value : [props.value]);

    const getSelectedValueLabel = () => {
        if (!props.multiple) {
            return props.value;
        }

        const isAllSelected = props.multiple && props.value.length === props.items?.length;

        return isAllSelected ? 'Todos' : selectedItems?.map((item) => item.label).join(', ') || 'Todos';
    };

    return (
        <FieldBase disabled={props.isDisabled} px={12} w='100%'>
            <XStack alignItems='center' w='100%'>
                <Stack width='75%'>
                    <Stack flexDirection='row' ai='center' pointerEvents='none'>
                        <Stack>
                            <Text color='#376A7B' fontSize={14}>
                                {props.label}{' '}
                            </Text>
                        </Stack>

                        <Stack flex={1}>
                            <Text color='#376A7B' fontSize={12} numberOfLines={1}>
                                {getSelectedValueLabel() ? `(${getSelectedValueLabel()})` : ''}
                            </Text>
                        </Stack>
                    </Stack>
                </Stack>

                {Platform.OS === 'web' && props.multiple && !!props.value?.length && (
                    <Stack
                        position='absolute'
                        right={22}
                        cursor='pointer'
                        onPress={(event) => {
                            props.onChange?.({ isAllSelected: false, value: [] });
                            event.stopPropagation?.();
                        }}
                    >
                        <Icon name='x' size={14} color='#376A7B' />
                    </Stack>
                )}

                <Stack position='absolute' right={0}>
                    <Icon name='chevron-down' size={24} color='#376A7B' />
                </Stack>
            </XStack>
        </FieldBase>
    );
};
