import React, { ComponentProps } from 'react';
import { Stack, StackProps, TextProps } from 'tamagui';
import { AlertContainer, AlertIcon, AlertText } from './styled';
import { Feather } from '@expo/vector-icons';

type Severity = 'success' | 'error' | 'info' | 'warn';

type Props = StackProps & {
    message: string;
    severity?: Severity;
    icon?: ComponentProps<typeof Feather>['name'];
    textProps?: TextProps;
};

const Alert = ({ severity = 'success', message, textProps, ...props }: Props) => {
    return (
        <AlertContainer severity={severity} {...props}>
            <Stack mr={10}>
                {props.icon ? (
                    <>
                        {/* @ts-ignore */}
                        <AlertIcon severity={severity} size={16} name={props.icon} style={{ color: textProps?.color }} />
                    </>
                ) : (
                    <>
                        {/* @ts-ignore */}
                        <AlertIcon severity={severity} size={16} style={{ color: textProps?.color }} />
                    </>
                )}
            </Stack>

            <AlertText severity={severity} flex={1} {...textProps}>
                {message}
            </AlertText>
        </AlertContainer>
    );
};

export default Alert;
