import { useCallback } from 'react';
import { SelectProps, SelectValue } from '../SelectProps';
import { Platform } from 'react-native';

export type HandleOnChangeOptions = {
    isConfirm: boolean;
};

export function useOnChange(props: SelectProps) {
    const handleOnChange = useCallback(
        (items: SelectValue[], options?: HandleOnChangeOptions) => {
            if (Platform.OS !== 'web' && !options?.isConfirm) return;

            if (props.multiple) {
                props.onChange?.({ value: items, isAllSelected: items.length === props.items?.length });
            } else {
                props.onChange?.({ value: items[0] });
            }
        },
        [props.multiple, props.items]
    );

    return { handleOnChange };
}
