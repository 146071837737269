import { Aviary } from '@/user/entities/Aviary';
import { useFarmAviaries } from '@/user/hooks/useFarmAviaries';
import { useSelectedFarm } from '@/user/store/SelectedFarmStore';
import { SelectV2 } from '@alltis/ui';
import React from 'react';

const FarmAviarySelector = () => {
    const farm = useSelectedFarm((store) => store.farm);

    const [selectedAviaries, setSelectedAviaries] = useSelectedFarm((store) => [store.aviaries, store.updateSelectedAviaries]);

    const aviaries = useFarmAviaries(farm?.id);

    const aviariesItems = aviaries.data?.map((aviary) => ({
        label: aviary.name,
        value: aviary.id,
    }));

    return (
        <SelectV2
            multiple
            label='Aviários'
            items={aviariesItems || []}
            isLoading={aviaries.isLoading}
            value={selectedAviaries?.map((aviary) => aviary?.id) || []}
            onChange={({ value, isAllSelected }) => {
                if (isAllSelected) {
                    return setSelectedAviaries([]);
                }

                setSelectedAviaries(value.map((id) => aviaries.data?.find((aviary) => aviary.id === id)) as Aviary[]);
            }}
            selectAllWhenEmpty
        />
    );
};

export default FarmAviarySelector;
