import Alert from '@/shared/components/ui/Alert';
import ViewState from '@/shared/components/ui/ViewState';
import { FlashList, FlashListProps } from '@shopify/flash-list';
import React, { useMemo, useState } from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import Animated, { SlideInDown, SlideOutDown } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Separator, Stack, Text, useMedia, View } from 'tamagui';
import { isWebOrTablet } from '../../../../config/constants/webOrTablet';
import { useNewUIEnabled } from '../../../../config/theme/hooks/useNewUIEnabled';
import { RefreshControl, RefreshControlProps } from '../RefreshControl';
import { Skeleton, SkeletonView } from '../Skeleton';
import PageListCard from './components/PageListCard';
import PageListCardContent from './components/PageListCardContent';
import PageListCardHeader from './components/PageListCardHeader';
import PageListCardInfo from './components/PageListCardInfo';
import dayjs from 'dayjs';

const AnimatedStack = Animated.createAnimatedComponent(Stack);

type Props<T> = {
    data: T[] | undefined;
    isError?: boolean;
    isLoading?: boolean;
    dataUpdatedAt?: Date | number;
} & Pick<
    FlashListProps<T>,
    | 'renderItem'
    | 'ListEmptyComponent'
    | 'ListHeaderComponent'
    | 'ListFooterComponent'
    | 'children'
    | 'estimatedItemSize'
    | 'keyboardShouldPersistTaps'
    | 'extraData'
> & {
        onRefresh?: RefreshControlProps['onRefresh'];
    };

const PageList = <T,>(props: Props<T>) => {
    const safeArea = useSafeAreaInsets();

    const media = useMedia();
    const isNewUIEnabled = useNewUIEnabled();
    const window = useWindowDimensions();

    const numColumns = useMemo(() => {
        const MAX_COLUMN_SIZE = media?.md ? 450 : 300;
        return Math.floor(window.width / MAX_COLUMN_SIZE);
    }, [window.width, media?.md, media?.lg, media?.sm]);

    const [isRefetching, setIsRefetching] = useState(false);

    const ListEmptyContent = () => {
        // eslint-disable-next-line react/prop-types
        if (props.ListEmptyComponent) return props.ListEmptyComponent;

        if (props.isLoading) {
            return (
                <View flexDirection='row' flexWrap='wrap' gap={14} pr={media.md ? 24 : 0}>
                    <Skeleton>
                        {new Array(9).fill(null).map((_, index) => (
                            <SkeletonView width={300} height={300} flexGrow={1} key={index} />
                        ))}
                    </Skeleton>
                </View>
            );
        }

        return <ViewState empty={props.data?.length === 0} error={props.isError} loading={props.isLoading} />;
    };

    const lastUpdateText = `Última atualização: ${dayjs(props.dataUpdatedAt).format('DD MMMM HH:mm')}`;

    return (
        <Stack flex={1} bg={isNewUIEnabled ? '#EBEBE9' : '#ffffff'}>
            <FlashList
                {...props}
                onRefresh={undefined}
                data={props.data || []}
                contentContainerStyle={{
                    paddingVertical: media.md ? 32 : 24,
                    paddingHorizontal: media.md ? 32 : 16,
                    paddingRight: media.md ? 8 : 16,
                }}
                estimatedItemSize={props.estimatedItemSize || 100}
                renderItem={(...renderItemProps) => (
                    <Stack flex={1} $sm={{ mr: 24 }} mr={0}>
                        {props.renderItem?.(...renderItemProps)}
                    </Stack>
                )}
                ListHeaderComponent={
                    <>
                        {Platform.OS === 'web' && !!props.dataUpdatedAt && (
                            <Text alignSelf='flex-end' color='#8CA9B3' fontSize={12} mb={12} pr={28}>
                                {lastUpdateText}
                            </Text>
                        )}

                        {props.ListHeaderComponent}
                    </>
                }
                ItemSeparatorComponent={() => (
                    <Separator $platform-native={{ my: 12 }} $platform-web={{ border: 'none', my: 12 }} />
                )}
                ListEmptyComponent={ListEmptyContent()}
                numColumns={isWebOrTablet ? numColumns : 1}
                key={isWebOrTablet ? numColumns : 1}
                refreshControl={
                    props.onRefresh ? (
                        <RefreshControl
                            onRefresh={props.onRefresh}
                            onStart={() => setIsRefetching(true)}
                            onFinish={() => {
                                setTimeout(() => setIsRefetching(false), 2000);
                            }}
                        />
                    ) : undefined
                }
            />
            {props.children}

            {isRefetching && !!props.dataUpdatedAt && (
                <AnimatedStack
                    position='absolute'
                    bottom={0}
                    width='100%'
                    entering={SlideInDown.springify().damping(1000).stiffness(300)}
                    exiting={SlideOutDown.duration(1000)}
                    zIndex={9999}
                >
                    <Alert
                        message={lastUpdateText}
                        severity='warn'
                        icon='clock'
                        bg='#05455A'
                        borderBottomEndRadius={0}
                        borderBottomLeftRadius={0}
                        borderTopEndRadius={0}
                        borderTopStartRadius={0}
                        alignItems='center'
                        jc='center'
                        textProps={{ flex: 0, color: '#ffffff' }}
                        pb={safeArea.bottom || undefined}
                    />
                </AnimatedStack>
            )}
        </Stack>
    );
};

PageList.Card = PageListCard;
PageList.CardHeader = PageListCardHeader;
PageList.CardContent = PageListCardContent;
PageList.CardInfo = PageListCardInfo;

export default PageList;
