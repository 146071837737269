import PopoverHeader from '@/alltis-ui-v2/components/Popover/components/Header';
import { SearchField } from '@alltis/ui';
import React, { useCallback } from 'react';
import { Platform } from 'react-native';
import { Stack } from 'tamagui';
import { SelectItem, SelectProps } from '../../SelectProps';
import removeAccents from 'remove-accents';

type Props = Pick<SelectProps, 'items'> & {
    onFilter: (items: SelectItem[]) => void;
};

const SelectHeader = (props: Props) => {
    const onSearch = useCallback(
        (text: string) => {
            const result = props.items?.filter((item) => {
                const toSearch = removeAccents(item.label || '').toLowerCase();
                const searchTerm = removeAccents(text).toLowerCase();

                return toSearch.includes(searchTerm);
            });

            props.onFilter(result || []);
        },
        [props.onFilter, props.items]
    );

    return (
        <Stack>
            {Platform.OS !== 'web' && <PopoverHeader title='Selecionar um item' divider={false} />}

            <Stack p={12} pt={0} $platform-web={{ px: 8, py: 4, pt: 8 }}>
                <SearchField onChangeText={onSearch} />
            </Stack>
        </Stack>
    );
};

export default SelectHeader;
